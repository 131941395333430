import {
  logoStyles,
  buttonStyles,
  headerStyles,
  headingStyles,
  buttonHoverStyles,
  primaryButtonStyles,
} from "./base"
import Theme from "../../interfaces/themes"
import { createResponsiveStyles } from "../../helpers/theme"

/**
 * Theme styles based on Theme UI
 *
 * 1. https://theme-ui.com/theming
 * 2. https://theme-ui.com/theme-spec
 *
 * @constant
 */
const theme: Theme = {
  useCustomProperties: false,
  breakpoints: ["375px", "576px", "768px", "992px", "1200px"],
  colors: {
    background: "#FFFFFF",
    text: "#262626", // text-black
    black: "#000000",
    white: "#FFFFFF",
    whiteTwo: "#FBFBFB",
    primary: "#1B6F65", // viridian
    primaryHover: "#26A295",
    primaryDisabled: "#95c2bd",
    primaryInputBorder: "#009688",
    secondary: "#1B6F65", // same as primary
    secondaryHover: "#26A295", // same as primary
    secondaryDisabled: "#95c2bd", // same as primary
    secondaryInputBorder: "#009688", // same as primary
    duckEggBlue: "#CBF1E6",
    inputPlaceholder: "#909F9E",
    blackBorder: "#979797",
    gray: "#808080",
    lightGray: "#D3D3D3",
    formBorder: "#E8F1F0",
  },
  buttons: {
    primary: {
      ...primaryButtonStyles,
    },
    secondary: {
      ...buttonStyles,
      fontWeight: 600,
      ":not(.Disabled):hover, :active": {
        ...buttonHoverStyles,
      },
      "&.Disabled": {
        bg: "whiteTwo",
        fontWeight: 600,
        cursor: "default",
        color: "primaryDisabled",
        borderColor: "primaryDisabled",
      },
    },
    default: {
      bg: "transparent",
      color: "primary",
      fontFamily: "body",
      border: "none",
    },
    roundedPrimary: {
      ...buttonStyles,
      fontWeight: 600,
      borderRadius: "24px",
      bg: "primary",
      color: "white",
      ":hover, :active": {
        color: "white",
        bg: "primaryHover",
        textDecoration: "none",
        borderColor: "primaryHover",
      },
    },
    navigation: {
      bg: "transparent",
      color: "text",
      minHeight: "26px",
      fontFamily: "body",
      letterSpacing: ".034em", // Fix for hover spacing
      ":active, :hover, &.active": {
        color: "text",
        letterSpacing: "0", // Fix for hover spacing
        fontWeight: "bold",
        borderBottom: "2px",
        textDecoration: "none",
        borderBottomColor: "primary",
        borderBottomStyle: "solid",
      },
    },
    languageSwitcher: {
      ...primaryButtonStyles,
      px: "0",
      py: "0",
      fontWeight: 600,
      letterSpacing: "1px",
      width: "46px",
      height: "46px",
      fontSize: "22px",
      bg: "white",
      color: "primary",
    },
    download: {
      width: "216px",
      variant: "buttons.primary",
      mt: createResponsiveStyles(5),
    },
  },
  fonts: {
    body: "'Open Sans', system-ui, sans-serif",
    heading: "'Montserrat', system-ui, sans-serif",
  },
  fontSizes:
    // 0, 1, 2, 3,   4,  5,  6,  7,  8,  9,  10
    [16, 18, 20, 22, 25, 30, 32, 34, 39, 45, 60],
  space:
    // 0, 1, 2, 3,  4,  5,  6,  7,  8,  9,  10,  11,  12,  13,  14
    [0, 5, 10, 15, 20, 25, 30, 40, 60, 80, 100, 120, 150, 180, 210],
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
    dark: 600,
    light: 300,
    normal: 400,
    display: 300,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.33,
    paragraph: 1.38,
  },
  sizes: {
    xs: 340,
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
  },
  zIndices: {
    body: 1,
    hero: 2,
    heroText: 3,
    header: 3,
    footer: 3,
    collapsibleNavigation: 4,
    tabs: 2,
    fixedCTA: 5,
  },
  borderWidths: [1, 2, 3, 5],
  styles: {
    h1: {
      ...headingStyles,
      color: "primary",
      fontSize: [4, 5, 5, 9],
    },
    h2: {
      ...headingStyles,
      color: "primary",
      fontSize: [3, 4, 4, 8],
    },
    h3: {
      ...headingStyles,
      fontSize: [2, 3, 3, 7],
    },
    h4: {
      ...headingStyles,
      fontSize: [1, 2, 2, 6],
    },
    h5: {
      ...headingStyles,
      fontSize: [1, 2, 2, 4],
    },
    p: {
      m: 0,
      fontSize: 0,
      fontFamily: "body",
      lineHeight: "paragraph",
    },
  },
  layout: {
    header: headerStyles,
    logo: logoStyles,
    mobileLogo: {
      ...logoStyles,
      maxWidth: "132px",
    },
    formHeader: {
      ...headerStyles,
      py: createResponsiveStyles(6, 5, 6, 5),
      px: createResponsiveStyles(6, 6, 8, 5),
    },
  },
}

export default theme
