import { BaseSxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../helpers/theme"

/**
 * Base heading styles (h1, h2, h3, h4, h5)
 *
 * @constant
 */
export const headingStyles: BaseSxStyleProp = {
  m: 0,
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
}

/**
 * Base styles for "absolute" position-ed elements
 *
 * @constant
 */
export const absoluteStyles: BaseSxStyleProp = {
  top: "0",
  left: "0",
  position: "absolute",
}

/**
 * Base styles for "fixed" position-ed elements
 *
 * @constant
 */
export const fixedStyles: BaseSxStyleProp = {
  ...absoluteStyles,
  position: "fixed",
}

/**
 * Base styles for "img" elements
 *
 * @constant
 */
export const imageStyles: BaseSxStyleProp = {
  maxWidth: "100%",
  height: "auto",
}

/**
 * Base styles for Logo components
 *
 * @constant
 */
export const logoStyles: BaseSxStyleProp = {
  width: "100%",
  maxWidth: createResponsiveStyles("90px", "132px"),
}

/**
 * Base styles for "header" elements
 *
 * @constant
 */
export const headerStyles: BaseSxStyleProp = {
  py: createResponsiveStyles(2, 4, 5),
  px: createResponsiveStyles(3, 7),
  width: "100%",
  zIndex: "header",
  backgroundColor: createResponsiveStyles("white", "white", "transparent"),
  "&.Scrolled": {
    backgroundImage: createResponsiveStyles(
      "none",
      "none",
      `linear-gradient(to bottom, white, rgba(255, 255, 255, 0) 165%);`
    ),
  },
}

/**
 * Base styles for "button" elements
 *
 * Defaults to "secondary" color-ed buttons.
 *
 * @constant
 */
export const buttonStyles: BaseSxStyleProp = {
  py: 2,
  px: 2,
  bg: "white",
  color: "text",
  fontSize: "16px",
  cursor: "pointer",
  fontFamily: "body",
  borderWidth: "2px",
  borderRadius: "3px",
  textAlign: "center",
  borderStyle: "solid",
  borderColor: "primary",
}

/**
 * Base styles for "button" elements' hover state
 *
 * Defaults to "secondary" color-ed buttons.
 *
 * @constant
 */
export const buttonHoverStyles: BaseSxStyleProp = {
  color: "text",
  borderWidth: "2px",
  bg: "duckEggBlue",
  textDecoration: "none",
}

/**
 * Base styles for "button" elements
 *
 * Defaults to "primary" color-ed buttons.
 *
 * @constant
 */
export const primaryButtonStyles: BaseSxStyleProp = {
  ...buttonStyles,
  bg: "primary",
  color: "white",
  fontWeight: 600,
  borderRadius: "4px",
  ":not(.Disabled):hover, :active": {
    color: "white",
    bg: "primaryHover",
    textDecoration: "none",
    borderColor: "primaryHover",
  },
  "&.Disabled": {
    cursor: "default",
    bg: "primaryDisabled",
    borderColor: "primaryDisabled",
  },
}
