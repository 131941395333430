/**
 * Create responsive styles based on Theme UI's array parser.
 *
 * 1. https://theme-ui.com/theming/#breakpoints
 * 2. https://theme-ui.com/guides/responsive-typography
 *
 * @param {number|string} mobile
 * @param {number|string} tablet
 * @param {number|string} full
 * @param {number|string} belowMobile
 *
 * @returns {Array<number|string>}
 */
export const createResponsiveStyles = (
  mobile: number | string,
  tablet?: number | string,
  full?: number | string,
  belowMobile?: number | string // iPhone 5, 4
): Array<number | string> => {
  let fullStyles = []
  let tabletStyles = []
  let mobileStyles = Array(3).fill(mobile)

  // iPhone 5, 4
  if (typeof belowMobile !== "undefined") {
    mobileStyles = [belowMobile, ...Array(2).fill(mobile)]
  }
  if (typeof tablet !== "undefined") {
    tabletStyles = Array(2).fill(tablet)
  }
  if (typeof full !== "undefined") {
    fullStyles = Array(1).fill(full)
  }

  return [...mobileStyles, ...tabletStyles, ...fullStyles]
}
